import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, CSSRulePlugin, ScrollToPlugin, TextPlugin)

export default defineNuxtPlugin(() => {
  return {
    provide: {
      gsap,
      scrollTrigger: ScrollTrigger,
    },
  }
})
