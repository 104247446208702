import revive_payload_client_0oaEfAzE2J from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_8mgWjpq6mc from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_p4t8drdJWc from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vi9kg9QoBz from "/app/node_modules/.pnpm/nuxt-site-config@2.2.11_@nuxt+devtools@1.3.6_@vue+compiler-core@3.4.27_nuxt@3.12.2_postcss@8._gdu6dbzkuhh2thnj3xfa54crw4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import directus_fdYmlw0WUJ from "/app/plugins/directus.ts";
import payload_client_KKAaKbJ3H2 from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_hE87ulzl2L from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_bzJiSvCRc0 from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.0_typescript@5.4.4_vue@3.4.29/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_Th2tHrQxG9 from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_WanohRyRTZ from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.12.2_rollup@4.14.0_vue@3.4.29/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import titles_FqALIdWcir from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.9_@lezer+common@1.2.1_@nuxt+devtools@1.3.6_@unhead+shared@1.9.4_@vue+com_fhlkwrrdw7kukhxewaobn2gxya/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_qzk4LWfAft from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.9_@lezer+common@1.2.1_@nuxt+devtools@1.3.6_@unhead+shared@1.9.4_@vue+com_fhlkwrrdw7kukhxewaobn2gxya/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_qvrizFC2dh from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.6_@vue+compiler-core@3.4.27_nuxt@3.12.2_po_guh7rqa23c6ijyr5l7ztxpma3i/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_9R1y7ptNu4 from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.5_@nuxt+devtools@1.3.6_@vue+compiler-core@3.4.27_nuxt@3.12.2_po_guh7rqa23c6ijyr5l7ztxpma3i/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import i18n_1mZyQZ2nN3 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.14.0_vue@3.4.29/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import chunk_reload_client_wFS0NZLe4S from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import gsap_qE0fizvEy0 from "/app/plugins/gsap.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import sonner_client_l6WBUspFNo from "/app/plugins/sonner.client.ts";
export default [
  revive_payload_client_0oaEfAzE2J,
  unhead_8mgWjpq6mc,
  router_p4t8drdJWc,
  _0_siteConfig_vi9kg9QoBz,
  directus_fdYmlw0WUJ,
  payload_client_KKAaKbJ3H2,
  check_outdated_build_client_hE87ulzl2L,
  plugin_vue3_bzJiSvCRc0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Th2tHrQxG9,
  plugin_WanohRyRTZ,
  titles_FqALIdWcir,
  defaults_qzk4LWfAft,
  siteConfig_qvrizFC2dh,
  inferSeoMetaPlugin_9R1y7ptNu4,
  unocss_MzCDxu9LMj,
  i18n_1mZyQZ2nN3,
  plugin_6Ph1iQzNHk,
  chunk_reload_client_wFS0NZLe4S,
  gsap_qE0fizvEy0,
  sentry_client_shVUlIjFLk,
  sonner_client_l6WBUspFNo
]