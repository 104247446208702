import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46pageSlug_93HaTbXNDuqUMeta } from "/app/pages/[...pageSlug].vue?macro=true";
import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as configuratorGcPfeVrbgAMeta } from "/app/pages/configurator.vue?macro=true";
import { default as _91slug_93jBuR8M2OHbMeta } from "/app/pages/pools/[slug].vue?macro=true";
import { default as indexqJvsbHT8zZMeta } from "/app/pages/pools/index.vue?macro=true";
import { default as _91slug_93rNUtF0rHPmMeta } from "/app/pages/projects/[slug].vue?macro=true";
import { default as indexdd0y1GP2UcMeta } from "/app/pages/projects/index.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
import { default as component_45stub0lQgVwEJ1rMeta } from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub0lQgVwEJ1r } from "/app/node_modules/.pnpm/nuxt@3.12.2_eslint@9.5.0_rollup@4.14.0_sass@1.77.6_typescript@5.4.4_vite@5.3.1/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___nl",
    path: "/nl/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___fr",
    path: "/fr/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/de/:pageSlug(.*)*",
    component: () => import("/app/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___nl",
    path: "/nl/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___nl",
    path: "/nl/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "configurator___nl",
    path: "/nl/configurator",
    component: () => import("/app/pages/configurator.vue").then(m => m.default || m)
  },
  {
    name: "configurator___en",
    path: "/en/configurator",
    component: () => import("/app/pages/configurator.vue").then(m => m.default || m)
  },
  {
    name: "configurator___fr",
    path: "/fr/configurator",
    component: () => import("/app/pages/configurator.vue").then(m => m.default || m)
  },
  {
    name: "configurator___de",
    path: "/de/configurator",
    component: () => import("/app/pages/configurator.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pools-slug___nl",
    path: "/nl/pools/:slug()",
    component: () => import("/app/pages/pools/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pools-slug___en",
    path: "/en/pools/:slug()",
    component: () => import("/app/pages/pools/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pools-slug___fr",
    path: "/fr/pools/:slug()",
    component: () => import("/app/pages/pools/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pools-slug___de",
    path: "/de/pools/:slug()",
    component: () => import("/app/pages/pools/[slug].vue").then(m => m.default || m)
  },
  {
    name: "pools___nl",
    path: "/nl/pools",
    component: () => import("/app/pages/pools/index.vue").then(m => m.default || m)
  },
  {
    name: "pools___en",
    path: "/en/pools",
    component: () => import("/app/pages/pools/index.vue").then(m => m.default || m)
  },
  {
    name: "pools___fr",
    path: "/fr/pools",
    component: () => import("/app/pages/pools/index.vue").then(m => m.default || m)
  },
  {
    name: "pools___de",
    path: "/de/pools",
    component: () => import("/app/pages/pools/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___nl",
    path: "/nl/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___en",
    path: "/en/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___fr",
    path: "/fr/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects-slug___de",
    path: "/de/projects/:slug()",
    component: () => import("/app/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects___nl",
    path: "/nl/projects",
    component: () => import("/app/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___en",
    path: "/en/projects",
    component: () => import("/app/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___fr",
    path: "/fr/projects",
    component: () => import("/app/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects___de",
    path: "/de/projects",
    component: () => import("/app/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___nl",
    path: "/nl/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___fr",
    path: "/fr/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___de",
    path: "/de/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: component_45stub0lQgVwEJ1rMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub0lQgVwEJ1r
  }
]